<template>
  <footer>
    <div class="footer__wrap">
      <div class="footer__top">
        <div class="footer__left">
          <div class="footer__menu">
            <a href="https://www.kirinholdings.com/jp/sitepolicy/"
              >サイトのご利用にあたって</a
            >
            <a href="https://www.kirinholdings.com/jp/privacypolicy/"
              >個人情報の保護</a
            >
            <a href="./app/qa">よくある質問</a>
          </div>
          <p class="footer__info">
            【お問い合わせ先】<br />
            GOLD EXPERIENCE事業事務局<br />
            <a href="mailto:goldexperience@kirin.co.jp"
              >goldexperience@kirin.co.jp</a
            >
          </p>
        </div>
        <div class="footer__right">
          <div class="footer__logo">
            <img src="../../assets/lp_new/footer_logo.png" alt="logo" />
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <p>
          © 2007-2022 Kirin Holdings Company, Limited.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
a,
a:link {
  text-decoration: underline;
  color: white;
}
footer {
  height: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  background: #272727;
}

footer p {
  margin: 0;
}

.footer__wrap {
  width: 100%;
  margin: 0 auto;
}

.footer__top {
  max-width: 1020px;
  margin: 50px auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 480px;
}

.footer__menu a {
  font-size: 14px;
}

.footer__info {
  font-size: 14px;
  margin-top: 30px;
}

.footer__right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
}

.footer__logo {
  width: 268px;
  height: 76px;
}

.footer__logo img {
  width: 100%;
}

.footer__bottom {
  border-top: solid 1px white;
  padding: 30px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-justify-content: center;
  justify-content: center;
}

.footer__bottom p {
  font-size: 13px;
  text-align: center;
  color: white;
}

@media screen and (max-width: 1020px) {
  .footer__top {
    max-width: 720px;
  }

  .footer__logo {
    width: 180px;
    height: 51px;
  }

  .footer__menu {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .footer__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: auto;
  }

  .footer__top {
    padding: 0 24px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 40px auto;
  }

  .footer__menu a {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .footer__info {
    font-size: 12px;
    margin-top: 20px;
  }

  .footer__logo {
    width: 120px;
    height: 34px;
  }

  .footer__bottom {
    padding: 20px 0;
  }
}
</style>
